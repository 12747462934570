import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import { getStationListWQI } from './../../api/api-qtdk'
import * as _ from 'lodash'

const { Option } = Select

const SelectStation = (props) => {

    const [data, setData] = useState([])
    const [value, setValue] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            const rs = await getStationListWQI()
            if (rs.success) {
                setData(rs.data)
                const value = _.get(_.first(rs.data), 'station.key', null)
                const data_key = _.keyBy(rs.data, item => item.station.key)
                setValue(value)
                props.getValueStation(data_key[value])
            }
        }
        fetchData()
    }, [])

    const handleChange = (value) => {
        const data_key = _.keyBy(data, item => item.station.key)
        setValue(value)
        props.getValueStation(data_key[value])
    }

    return (
        < div >
            <Select
                onChange={handleChange}
                style={{ width: '100%' }}
                value={value}
            >
                {!_.isEmpty(data) && _.map(data, i => <Option key={i['station']['key']} value={i['station']['key']}>{i['station']['name']}</Option>)}
            </Select>
        </ div>)
}

export default SelectStation