import React from 'react'
import styled from 'styled-components'
import './info.css'
import { Tooltip } from 'antd'

const View = styled.div``
const Text = styled.div`font-size: 10px; font-weight: 600;`
const Note = styled.div`font-size: 12px; font-weight: normal;`

const ICON_AQI = {
  "0 - 50": '/images/icon-aqi/ic-face-green.svg', // good - tốt
  "301-500": '/images/icon-aqi/ic-face-maroon.svg', // Hazardous - nguy hại
  "101 - 150": '/images/icon-aqi/ic-face-orange.svg', // bad - kém
  "201 - 300": '/images/icon-aqi/ic-face-purple.svg', // Very Unhealthy - rất xấu
  "151 - 200": '/images/icon-aqi/ic-face-red.svg', // Unhealthy - xấu
  "51 - 100": '/images/icon-aqi/ic-face-yellow.svg' // moderate - trung bình
}

const STYLE_ICON = {
  "0 - 50": 'auto',
  "301-500": 'auto',
  "101 - 150": 'auto',
  "201 - 300": '50%',
  "151 - 200": '50%',
  "51 - 100": '50%'
}

export default class InfoContainer extends React.PureComponent {

  _renderView() {
    const { value, status, color, textColor, name } = this.props
    return (
      <View className='d-container' style={{ backgroundColor: color || '#00e400' }}>
        <View className='d-header'>
          <Text className='d-text'>{this.props.title}</Text>
        </View>
        <View className='d-content' style={{ backgroundColor: color || '#00e400', color: textColor }}>
          {
            this.props.TextFirst ? <Text style={{ fontSize: 23 }}>{value}</Text> : <Note>{status}</Note>
          }
          {
            this.props.TextFirst ? <img src={ICON_AQI[name]} style={{ width: STYLE_ICON[name], height: STYLE_ICON[name] }} alt='' /> : null
          }
          {
            this.props.TextFirst ? <Note>{status}</Note> : <Text style={{ fontSize: 20 }}>{value}</Text>
          }
        </View>
      </View>
    )
  }

  render() {
    return this.props.TextFirst ? <Tooltip title={this.props.note} placement='right'>{this._renderView()}</Tooltip> : this._renderView()
  }
}