import React from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
import connect from '../@redux/connect'
import { onChangeStation } from '../@redux/actions/station'
import * as _ from 'lodash'

const { Option } = Select;
const StationSelect = styled(Select)`
  width: 100%;
`
const WrapperView = styled.div`
  margin: 6px;
`

@connect(state => ({
  stations: _.get(state, 'stationStore.stations', []),
  station: _.get(state, 'stationStore.station', {}),
  station_type: _.get(state, 'stationStore.station_type', '')
}), { onChangeStation })
class StationSelected extends React.PureComponent {

  handleChange = value => {
    const obj = _.keyBy(this.props.stations, 'station_code')
    if (obj[value]) this.props.onChangeStation(obj[value], this.props.station_type)
  }

  handleFilterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

  render() {
    return (
      <WrapperView>
        <StationSelect
          showSearch
          value={_.get(this.props, 'station.station_code', '')}
          placeholder="Chọn Trạm quan trắc"
          optionFilterProp="children"
          onChange={this.handleChange}
          filterOption={this.handleFilterOption}
        >

          {
            _.map(this.props.stations, item =>
              <Option key={item.station_code} data={item} value={item.station_code}>{item.station_name}</Option>
            )}
        </StationSelect>
      </WrapperView>
    )
  }
}

export default StationSelected