import React from 'react'
import AQI from "../../../constants/aqi"
import './tableMB.css'
import moment from "moment";
import IC_TOT from "../../../images/icon-aqi/ic-face-green-original.svg"
import IC_TRUNGBINH from "../../../images/icon-aqi/ic-face-yellow-original.svg"
import IC_KEM from "../../../images/icon-aqi/ic-face-orange-original.svg"
import IC_XAU from "../../../images/icon-aqi/ic-face-red-original.svg"
import IC_RAT_XAU from "../../../images/icon-aqi/ic-face-purple-original.svg"
import IC_NGUY_HAI from "../../../images/icon-aqi/ic-face-maroon-original.svg"

 //   "0 - 50": '/images/icon-aqi/ic-face-green-original.svg', // good - tốt
  // "51 - 100": '/images/icon-aqi/ic-face-yellow-original.svg', // moderate - trung bình
  // "101 - 150": '/images/icon-aqi/ic-face-orange-original.svg', // bad - kém
  // "201 - 300": '/images/icon-aqi/ic-face-purple-original.svg', // Very Unhealthy - rất xấu
  // "151 - 200": '/images/icon-aqi/ic-face-red-original.svg', // Unhealthy - xấu
  // "301-500": '/images/icon-aqi/ic-face-maroon-original.svg'
const tableMB = (props) => {
  console.log("========>", props.aqiStationList);
  let bg,text,desc,color,status,note,state;
  function checkQi(qi){
    
    AQI.map((aqiItem,index1)=>{
      if(qi>= aqiItem.from && qi<= aqiItem.to){
        bg= aqiItem.bg;
        text=aqiItem.text;
        note=aqiItem.note;
        color=aqiItem.color;
        status=aqiItem.status;
        }
        if(qi<=50){
            state= IC_TOT;
        }
        else if(qi<=100){
            state= IC_TRUNGBINH;
        }
        else if(qi<=150){
            state= IC_KEM;
        }
        else if(qi<=200){
            state= IC_XAU;
        }
        else if(qi<=300){
            state= IC_RAT_XAU;
        }
        else{
            state= IC_NGUY_HAI;
        }
    })
   
 
}

  return (
    <div className='tableMB'>
     

        {

          props.aqiStationList && props.aqiStationList.map((item) => {
            return item.map((bitem) => {
              return <>
                 <table>
                   {checkQi(bitem.qi)}
                <tr>
                  <td style={{fontWeight: "600"}}>Tên trạm</td>
                  <td  style={{color: color}}>{bitem.station_name}</td>
                </tr>

                <tr>
                  <td style={{fontWeight: "600"}}>VN_AQI Giờ</td>
                  <td  style={{color: color, textAlign:"center" }} >
                    <div className='table-aqi-hour'>
                      <p>{bitem.qi}</p>
                      <img src={state}  />
                      <p>{status}</p>
                    </div>
                    <p style={{color: "white",marginTop:"10px"}}>{ moment(bitem.qi_time).utc().format("DD/MM/YYYY HH:mm")}</p>
                   
                  </td>
                </tr>
                {checkQi(bitem.qi_day)}
                <tr >
                  <td style={{fontWeight: "600"}}>VN_AQI Ngày</td>
                  <td  style={{color: color, textAlign:"center"}} >
                    <div className='table-aqi-hour'>
                      <p>{bitem.qi_day}</p>
                      <p><img src={state}  /></p>
                      <p>{status}</p>
                    </div>
                    <p style={{color: "white" ,marginTop:"10px"}}>{ moment(bitem.qi_day_time).utc().format("DD/MM/YYYY")}</p>
                   
                  </td>
                </tr>
                {checkQi(bitem.qi)}
                <tr>
                  <td style={{fontWeight: "600"}}>Khuyến nghị về sức khỏe (theo AQI Giờ)</td>
                  <td style={{color: color}}>
                    {note}
                  </td>
                </tr>
                </table>

              </>
            })
          })
        }

      
      

      {/* <table>
        <tr>
          {
            props.aqiStationList && props.aqiStationList.map((item) => {
              return item.map((bitem) => {
                return <th>{bitem.station_name}</th>

              })
            })
          }
        </tr>
        <tr>
          <td>Alfreds Futterkiste</td>
          <td>Maria Anders</td>
          <td>Germany</td>
        </tr>
        <tr>
          <td>Centro comercial Moctezuma</td>
          <td>Francisco Chang</td>
          <td>Mexico</td>
        </tr>
        <tr>
          <td>Ernst Handel</td>
          <td>Roland Mendel</td>
          <td>Austria</td>
        </tr>
        <tr>
          <td>Island Trading</td>
          <td>Helen Bennett</td>
          <td>UK</td>
        </tr>
        <tr>
          <td>Laughing Bacchus Winecellars</td>
          <td>Yoshi Tannamuri</td>
          <td>Canada</td>
        </tr>
        <tr>
          <td>Magazzini Alimentari Riuniti</td>
          <td>Giovanni Rovelli</td>
          <td>Italy</td>
        </tr>
      </table> */}

    </div>
  )
}

export default tableMB