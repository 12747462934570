import React from 'react'
import styled from 'styled-components'
// import connectAutoDispatch from '../../@redux/connect'
import * as _ from 'lodash'
import Highcharts from 'react-highcharts'
// import moment from 'moment'
import ChartLegend from './chart-legend'
import { MEASURINGS } from '../../constants/aqi'

const colors = Highcharts.Highcharts.getOptions().colors

const Wrapper = styled.div`
flex-direction: column;
display: flex; width: 100%; height: 158px;
`
// @connectAutoDispatch((state,ownProps) => {
//   const indicator = _.get(ownProps,'indicator',{})
//   let data = _.get(state,'stationStore.data',[])
//   let max = 0
//   data = _.map(data,item => {
//     const val = _.toNumber(_.get(item,[indicator.name],0))
//     if (val > max) max = val
//     return [item.time,val]
//   })
//   return {
//     max,
//     indicator,
//     data,
//     station: _.get(state,'stationStore.station',{}),
//     aqiDay: _.get(state,'stationStore.aqiDay',{}),
//   }
// })
class ChartView extends React.Component {

  // shouldComponentUpdate(nextProps) {
  //   return !_.isEqual(nextProps.data,this.props.data)// || !_.isEqual(nextProps.indicator,this.props.indicator);
  // }

  state = { selectedList: [] }

  handleLegedChange = (checked, val) => {
    // console.log(this.refs.mChart.props);
    let selectedList = this.state.selectedList
    if (checked) selectedList = _.filter(selectedList, t => t !== val)
    else selectedList.push(val)
    this.setState({ selectedList })
  }

  render() {
    const yAxis = {
      lineWidth: 1,
      title: {
        text: ''
      },
      min: 0,
      // max: this.props.max + 10,
      type: 'linear'
    }
    const selectedList = _.difference(MEASURINGS, this.state.selectedList)
    
    let diffKey = null
    const indicators = _.keyBy(this.props.indicators || [], 'key')
    if (_.size(selectedList) === 1) diffKey = indicators[selectedList[0]]
    if (diffKey && diffKey.qcvn_detail_max_value) {
      const max = _.toNumber(diffKey.qcvn_detail_max_value)
      if (max > (yAxis.max || 0)) yAxis.max = max + 10
      yAxis.plotLines = [
        {
          value: max,
          color: 'red',
          dashStyle: 'shortdash',
          width: 1,
          label: {
            text: `Max ${max}`
          }
        }
      ]
    }

    const rs = {}
    let d = this.props.data
    _.forEach(d, item => {
      _.forEach(selectedList, m => {
        if (!rs[m]) rs[m] = []
        const val =  _.toNumber(_.get(item, ['data', m], 0))
        if (diffKey && yAxis.max && yAxis.max < val) yAxis.max = val + 10
        rs[m].push([item.time, val])
      })
    })
    const series = []
    let inx = 0
    _.forIn(rs, (values, key, i) => {
      series.push({
        marker: { enabled: false },
        cursor: 'pointer', visible: !_.includes(this.state.selectedList, key),
        name: key, data: values, lineWidth: 1.5, color: colors[inx]
      })
      inx = inx + 1
    })

    return (
      <Wrapper>
        <Highcharts ref='mChart' config={
          {
            chart: {
              type: 'spline',
              zoomType: 'x',
              // width: 310,
              height: 205,//118,
              backgroundColor: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                  [0, '#cce1fc'], // start
                  [1, '#9fc9fc'] // end
                ]
              },
              spacing: [6, 6, 6, 6],
            },
            credits: {
              enabled: false
            },
            title: {
              text: '',
            },
            subtitle: {
              text: ''
            },
            tooltip: {
              valueDecimals: 2,
              xDateFormat: '<b>Lúc</b>: <i>%Hh %d-%m-%Y</i>',
              pointFormat: "<span style='color:{point.color}'>●</span> {series.name}: <b>{point.y}</b><br/>",
            },
            xAxis: {
              // categories,
              type: 'datetime',
              dateTimeLabelFormats: {
                millisecond: '%Hh',
                second: '%Hh',
                minute: '%H:%M',
                hour: '%Hh',
                day: '%e. %b',
                week: '%e. %b',
                month: '%b \'%y',
                year: '%Y'
              }
            },
            yAxis,
            legend: {
              enabled: false,
              symbolPadding: 2, symbolWidth: 5, showCheckbox: true,
              itemDistance: 5, padding: 2, lineHeight: 10, margin: 4,
              itemStyle: {
                // color: 'white',
                fontWeight: 'bold',
                fontSize: '8px'
              },
            },
            series//: this.state.series || []
          }
        } />
        <ChartLegend colors={colors} onLegendChange={this.handleLegedChange} />
      </Wrapper>
    )
  }
}
export default ChartView