import React, { Component } from 'react'
import './index.css'
import './led-content.css'
import LevelContainer from "./level";
import styled from 'styled-components'
import api from "../../api";
import * as _ from 'lodash'
import Clock from './clock'
import Timer from './timer'
import aqiLevels from '../../constants/aqi'
import moment from 'moment'
import { MEASURINGS } from '../../constants/aqi'

const ImageLogo = styled.img`
  width: 96px;
  height: 96px;
  margin-right: 12px
`
const ImageAQI = styled.img`width: 50%;`
const AQITitleText = styled.div`font-size: 32px; font-weight: 500; color: #fff`
const AQINoteText = styled.div`font-size: 20px; font-weight: 300; text-align:center; color: #fff`
const AQIValueText = styled.div`font-size: 72px; font-weight: 900`
const AQIStatusText = styled.div`font-size: 44px; font-weight: 700`


const station_type = 4

const ICON_AQI = {
    "0 - 50": '/images/icon-aqi/ic-face-green-original.svg', // good - tốt
    "51 - 100": '/images/icon-aqi/ic-face-yellow-original.svg', // moderate - trung bình
    "101 - 150": '/images/icon-aqi/ic-face-orange-original.svg', // bad - kém
    "201 - 300": '/images/icon-aqi/ic-face-purple-original.svg', // Very Unhealthy - rất xấu
    "151 - 200": '/images/icon-aqi/ic-face-red-original.svg', // Unhealthy - xấu
    "301-500": '/images/icon-aqi/ic-face-maroon-original.svg' // Hazardous - nguy hại
}

const RECOMMENDATION_CONTENT = {
    "0 - 50": ["Tự do thực hiện các hoạt động ngoài trời.", "Tự do thực hiện các hoạt động ngoài trời."],
    "51 - 100": ["Tự do thực hiện các hoạt động ngoài trời.", "Nên theo dõi các triệu chứng như ho hoặc khó thở, nhưng vẫn có thể hoạt động bên ngoài."],
    "101 - 150": ["Những người thấy có triệu chứng đau mắt, ho hoặc đau họng... nên cân nhắc giảm các hoạt động ngoài trời. Đối với học sinh, có thể hoạt động bên ngoài, nhưng nên giảm bớt việc tập thể dục kéo dài.", "Nên giảm các hoạt động mạnh và giảm thời gian hoạt động ngoài trời. Những người mắc bệnh hen suyễn có thể cần sử dụng thuốc thường xuyên hơn."],
    "151 - 200": ["Mọi người nên giảm các hoạt động mạnh khi ở ngoài trời, tránh tập thể dục kéo dài và nghỉ ngơi nhiều hơn trong nhà.", "Nên ở trong nhà và giảm hoạt động mạnh. Nếu cần thiết phải ra ngoài, hãy đeo khẩu trang đạt tiêu chuẩn."],
    "201 - 300": ["Mọi người hạn chế tối đa các hoạt động ngoài trời và chuyển tất cả các hoạt động vào trong nhà. Nếu cần thiết phải ra ngoài, hãy đeo khẩu trang đạt tiêu chuẩn.", "Nên ở trong nhà và giảm hoạt động mạnh."],
    "301-500": ["Mọi người nên ở trong nhà, đóng cửa ra vào và cửa sổ. Nếu cần thiết phải ra ngoài, hãy đeo khẩu trang đạt tiêu chuẩn.", "Mọi người nên ở trong nhà, đóng cửa ra vào và cửa sổ. Nếu cần thiết phải ra ngoài, hãy đeo khẩu trang đạt tiêu chuẩn."]
}
export default class LedAQI extends Component {
    

    constructor(props) {
        super(props)
        this.state = {
            
            max_order: 0,
            station_order: 0,
            stationsList: [],
            station_name: '',
            station_data: {},
            aqi_data: {},
            aqi_1d_data: {},
            qi_time: ''
        }
    }
   

    getAqiFrom(val) {
        if (val === null) return { value: '-', status: '' }
        val = _.round(val)
        const level = _.find(aqiLevels, ({ to, from }) => _.inRange(val, from, to))
        return { ...level, value: val }
    }

    checkStationsListAndGetData = async station_list => {
        console.log(station_list);
        let station_order = this.state.station_order
        let max_order = _.size(station_list)
        let this_station = station_list[station_order]
        let aqi_data = {}
        let aqi_1d_data = {}
        const station_id = _.get(this_station, 'id', '')
        const qi_time = _.get(this_station, 'qi_time', '')
        const { success, data } = await api.get_api_day(station_id)
        if (success) {
            aqi_data = this.getAqiFrom(_.get(data, 'aqi_hour.aqi', '-'))
            aqi_1d_data = this.getAqiFrom(_.get(data, 'data_1d.aqi', '-'))
            this.setState({
                stationsList: station_list,
                station_data: data,
                station_name: _.get(this_station, 'station_name', ''),
                station_order: station_order < max_order - 1 ? station_order + 1 : 0,
                aqi_1d_data,
                aqi_data,
                max_order,
                qi_time
            })
        }
    }

    fetchData = async () => {
        const { success, data } = await api.get_stations(station_type)
        if (success) {
            this.checkStationsListAndGetData(data)
        }
    }

    
  
    render() {

        return <div className='led-container'>
        <div className='led-header'>
            <div>
                <ImageLogo src='./logo.png' />
                <h3 className='text-blue'>SỞ TÀI NGUYÊN VÀ MÔI TRƯỜNG TỈNH GIA LAI</h3>
            </div>
            <div>
                <Clock />
            </div>
        </div>
        <div className='led-station-name led-border-top'>
            <div className='led-border-right'>
                <h3 className='big-bold-text'>{this.state.station_name}</h3>
                <Timer fetchData={this.fetchData} />
            </div>
            <div>
                <h3 className='big-bold-text'>AQI giờ từng thông số</h3>
            </div>
        </div>
        <div className='led-content led-border-top'>
            <div className='led-content-children led-border-right' >
                <div className='led-content-grandchildren' >
                    <div className='led-content-greatgrandchildren led-border-right'>
                        <AQIStatusText style={{ color: _.get(this.state, 'aqi_data.color', '#00e400') }}>{
                            _.get(this.state, 'aqi_data.status', '-')}
                        </AQIStatusText>
                        <ImageAQI src={ICON_AQI[_.get(this.state, 'aqi_data.name', '0 - 50')]} />
                        <AQINoteText>{_.get(this.state, 'aqi_data.note', '-')}</AQINoteText>
                    </div>
                    <div className='led-content-greatgrandchildren led-border-right'>
                        <AQITitleText>VN_AQI Giờ</AQITitleText>
                        <AQIValueText style={{ color: _.get(this.state, 'aqi_data.color', '#00e400') }}>
                            {_.get(this.state, 'aqi_data.value', '-')}
                        </AQIValueText>
                        {
                            this.state.qi_time && (
                                <AQINoteText>
                                    {moment(this.state.qi_time).subtract({ hours: 7 }).format('HH:mm DD/MM/YYYY')}
                                </AQINoteText>
                            )
                        }
                    </div>
                    <div className='led-content-greatgrandchildren'>
                        <AQITitleText>VN_AQI Ngày</AQITitleText>
                        <AQIValueText style={{ color: _.get(this.state, 'aqi_1d_data.color', '#00e400') }}>
                            {_.get(this.state, 'aqi_1d_data.value', '-')}
                        </AQIValueText>
                        {
                            this.state.qi_time && (
                                <AQINoteText>
                                    {moment(this.state.qi_time).subtract({ hours: 7 }).format('DD/MM/YYYY')}
                                </AQINoteText>
                            )
                        }
                    </div>
                </div>
                <div className='led-content-grandchildren led-border-top'>
                    <h3 className='big-bold-text'>Khuyến nghị về sức khỏe</h3>
                    <h3 className='normal-text'>Đối với người bình thường: {RECOMMENDATION_CONTENT[_.get(this.state, 'aqi_data.name', '0 - 50')][0]}</h3>
                    <h3 className='normal-text'>Đối với người nhạy cảm: {RECOMMENDATION_CONTENT[_.get(this.state, 'aqi_data.name', '0 - 50')][1]}</h3>
                </div>
            </div>
            <div className='led-content-children' >
                {
                    MEASURINGS.map(item => {
                        const measuring_data = this.getAqiFrom(_.get(this.state, ['station_data', 'aqi_hour', item], ''))
                        return (
                            <div key={item} className='led-border-top'>
                                <h3 className='measuring-text' style={{ color: _.get(this.state, 'measuring_data.color', '#00e400') }}>{item}</h3>
                                <h3 className='measuring-text' style={{ color: _.get(this.state, 'measuring_data.color', '#00e400') }}>{measuring_data.value}</h3>
                            </div>
                        )
                    }
                    )
                }
            </div>
        </div>
        <div className='led-footer led-border-top'>
            <LevelContainer />
        </div>
        </div >
    }
}
