import React from 'react'
import './index.css'
import './led-multiple.css'
import styled from 'styled-components'
import * as _ from 'lodash'
import aqiLevels from '../../../constants/aqi'
import moment from 'moment'
const ImageAQI = styled.img`height: 48px; margin: 4px`

const ICON_AQI = {
  "0 - 50": '/images/icon-aqi/ic-face-green-original.svg', // good - tốt
  "51 - 100": '/images/icon-aqi/ic-face-yellow-original.svg', // moderate - trung bình
  "101 - 150": '/images/icon-aqi/ic-face-orange-original.svg', // bad - kém
  "201 - 300": '/images/icon-aqi/ic-face-purple-original.svg', // Very Unhealthy - rất xấu
  "151 - 200": '/images/icon-aqi/ic-face-red-original.svg', // Unhealthy - xấu
  "301-500": '/images/icon-aqi/ic-face-maroon-original.svg' // Hazardous - nguy hại
}
const AQITitleText = styled.div`font-size: 18px; font-weight: 500; color: ${props => props.color || '#00e400'}; text-align: justify`
const AQINoteText = styled.div`font-size: 16px; font-weight: 300; color: ${props => props.color || '#fff'}; text-align: center`
const AQIValueText = styled.div`font-size: 20px; font-weight: 500; color: ${props => props.color || '#00e400'};`

const TableRow = props => {
  return (
    <div className='led-multiple-content led-border-top-big'>
      <div className='led-multiple-content-child led-border-right-big'>
        <AQITitleText color={_.get(props, 'aqi_data.color', '#00e400')}>
          {props.item.station_name}
        </AQITitleText>
      </div>
      <div className='led-multiple-content-aqi-child led-border-right-big'>
        <div className='led-multiple-aqi-row'>
          <div className='led-multiple-aqi-row-child'>
            <AQIValueText color={_.get(props, 'aqi_data.color', '#00e400')}>
              {props.aqi_data.value}
            </AQIValueText>
          </div>
          <div className='led-multiple-aqi-row-child'>
            <ImageAQI src={ICON_AQI[_.get(props, 'aqi_data.name', '0 - 50')]} />
          </div>
          <div className='led-multiple-aqi-row-child'>
            <AQIValueText color={_.get(props, 'aqi_data.color', '#00e400')}>
              {_.get(props, 'aqi_data.status', '-')}
            </AQIValueText>
          </div>
        </div>
        <AQINoteText>{moment(props.item.qi_time).subtract({ hours: 7 }).format('HH:mm DD/MM/YYYY')}</AQINoteText>
      </div>

      <div className='led-multiple-content-aqi-child led-border-right-big'>
        <div className='led-multiple-aqi-row'>
          <div className='led-multiple-aqi-row-child'>
            <AQIValueText color={_.get(props, 'aqi_day_data.color', '#00e400')}>
              {props.aqi_day_data.value}
            </AQIValueText>
          </div>
          <div className='led-multiple-aqi-row-child'>
            <ImageAQI src={ICON_AQI[_.get(props, 'aqi_day_data.name', '0 - 50')]} />
          </div>
          <div className='led-multiple-aqi-row-child'>
            <AQIValueText color={_.get(props, 'aqi_day_data.color', '#00e400')}>
              {_.get(props, 'aqi_day_data.status', '-')}
            </AQIValueText>
          </div>
        </div>
        <AQINoteText>{moment(props.item.qi_day_time).format('DD/MM/YYYY')}</AQINoteText>
      </div>
      <div className='led-multiple-content-last-child'>
        <AQITitleText color={_.get(props, 'aqi_data.color', '#00e400')}>
          {_.find(aqiLevels, { 'name': props.aqi_data.name }).note}
        </AQITitleText>
      </div>
    </div>
  )
}
export default TableRow

