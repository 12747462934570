import React, { useEffect } from 'react'
import * as _ from 'lodash'
import { getDataChart } from './../../api/api-qtdk'
import Highcharts from 'highcharts'
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);

export default function BoxChart(props) {

    useEffect(() => {
        const fetchData = async () => {
            const rs = await getDataChart()
            if (rs.success) {
                renderHighChart(rs.data, props.stationSelect)
            }
        }

        fetchData()
    }, [props.stationSelect])

    const getCategories = (year, data_, stationSelect_) => {
        const station_key = _.get(stationSelect_, 'station.key', null)
        let rs = []
        if (year) {
            let data_temp = _.filter(data_, i => i.station.key === station_key)
            rs = _.map(data_temp, i => i.plan.month)
        }
        return _.orderBy(rs, [], ['asc'])
    }

    const getData = (stationSelect_, categories_, data_) => {
        let rs = []
        const station_key = _.get(stationSelect_, 'station.key', null)
        rs = _.map(data_, i => { if (i.station.key === station_key) return { key: i.plan.month, value: i.value } })
        return _.map(_.orderBy(_.compact(rs), ['key'], ['asc']), ii => ii.value)
    }

    const getYear = (data_) => {
        return _.get(_.first(data_), 'plan.years', '')
    }

    const renderHighChart = (data_, stationSelect_) => {
        const year = getYear(data_)
        const categories = getCategories(year, data_, stationSelect_)
        const dataChart = getData(stationSelect_, categories, data_)

        Highcharts.chart('box-chart', {

            title: {
                text: `DIỄN BIẾN CHẤT LƯỢNG NƯỚC QUA CÁC ĐỢT TRONG NĂM ${year} `,
                style: {
                    fontFamily: 'Times New Roman'
                }
            },

            subtitle: {
                text: `Dữ liệu mới nhất năm ${year} `,
                style: {
                    fontFamily: 'Times New Roman',
                    fontSize: '15px',
                    color: '#0080ff',
                    fontWeight: 'bold'
                }
            },

            yAxis: {
                title: {
                    text: ''
                }
            },

            xAxis: {
                categories: _.map(categories, i => `Đợt ${i}`)
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
            },
            series: [{
                name: 'VN-WQI',
                data: dataChart
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }

        });
    }


    return (
        <div id='box-chart' style={{ width: '100%', height: 250 }}>
        </div>
    )
}
