import React from 'react'
import styled from 'styled-components'
import { Icon } from 'antd'
import moment from 'moment'
import DataChart from './data-chart'
import DataList from './data-list'
import * as _ from 'lodash';

const View = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  margin-right: 8px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: start;
  align-items: center;
  align-content: center;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 600;
  background-color: #fff;
`

const H3 = styled.div`
font-size: 13px;
ont-weight: bold;
font-style: normal;
color: #585858;
`

const WeatherView = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
`

const WEl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
`
const ImageW = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 2px
`

const WText = styled.span`
font-size: 10px;
font-weight: bold;
`

const WeatherEl = ({ alt, src, val }) => (
  <WEl>
    <ImageW alt={alt} src={src} />
    <WText>{val}</WText>
  </WEl>
)

export default class ContentContainer extends React.PureComponent {

  state = { isList: true }

  handleListChange = () => this.setState({ isList: true })
  handleChartChange = () => this.setState({ isList: false })
  getTime () {
    let time = moment()
    if (this.props.station.last_time) time = moment(this.props.station.last_time).subtract(7, 'hours')
    return time.format('DD/MM/YYYY')
  }
  render() {
    const temp = _.get(this.props, 'data_min.Temp', null)
    const rh = _.get(this.props, 'data_min.RH', null)
    return (
      <View>
        <Header>
          <H3>Kết quả trung bình ({this.getTime()})</H3>
          <WeatherView>
            {temp && <WeatherEl style={{marginRight: 12}} alt='' src='./images/cloud.png' val={`${_.round(temp)} oC`} />}
            { rh && <WeatherEl alt='' src='./images/humidity.png' val={`${_.round(rh)}%`} />}
          </WeatherView>
          <Icon type="unordered-list"
            style={{ color: this.state.isList ? '#1890FF' : '#727272', marginRight: 8 }}
            onClick={this.handleListChange}
          />
          <Icon type="line-chart" style={{ color: this.state.isList ? '#727272' : '#1890FF' }}
            onClick={this.handleChartChange}
          />
        </Header>
        {
          this.state.isList ? <DataList data={this.props.data} /> : <DataChart indicators={this.props.indicators} data={this.props.data} />
        }
      </View>
    )
  }
}