import React from 'react'
import styled from 'styled-components'
import wqiLevels from '../../constants/wqi-array'
import { Tooltip } from 'antd'
const View = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  height: 22px;
`
const Text = styled.div`font-size: 9px; font-weight: 600; margin-top: -1px;`
const Note = styled.div`font-size: 8px; font-weight: normal; margin-top: -2px; margin-bottom: -1px; font-style: italic;`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: ${props => props.bgColor || '#00e400'}
  color: ${props => props.tColor || '#000'}
`

const RowLev = styled(Tooltip)` flex: 1; display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
`
const Stick = styled.div`
  width: 10px; height: 10px; border-radius: 5px;
  background-color: ${props => props.bgColor || '#00e400'}
  margin-right: 6px;
  c
`

const ViewEl = ({ content, name, color, title }) => (
  <RowLev title={content}>
    <Stick bgColor={color} />
    <Col bgColor='transparent'>
      <Text>{name}</Text>
      <Note>{title}</Note>
    </Col>
  </RowLev>
)

export default class LevelContainer extends React.PureComponent {
  render() {
    return (
      <View style={{
        // borderTopColor: '#ddd', borderTopWidth: 0.5, borderTopStyle: 'solid'
      }}>
        {
          wqiLevels.map(it => <ViewEl key={it.name} {...it} />)
        }
      </View>
    )
  }
}