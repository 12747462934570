import React from 'react'
import styled from 'styled-components'
import InfoView from './info'
import './index.css'
import LevelContainer from './level'
import ContentContainer from './d-content'
import * as _ from 'lodash'
import moment from 'moment'
import aqiLevels from '../../constants/aqi'
import StationSelected from '../../components/station-selected';
import Recommandation from './recommendation'
// import api from '../../api'
// import data from './face-data'
import connectAutoDispatch from '../../@redux/connect'


const View = styled.div``
const Left = styled.div`padding-left: 6px;`
const Right = styled.div`padding-right: 6px;`
const Body = styled.div`
  display: flex;
  flex-direction: row;
`
const Content = styled.div`flex: 1;`

@connectAutoDispatch(state => ({
  station: _.get(state, 'stationStore.station', {}),
  aqi: _.get(state, 'stationStore.aqiDay.data_1d', {}),
  data_min: _.get(state, 'stationStore.aqiDay.data_min', {}),
  aqi_hour: _.get(state, 'stationStore.aqiDay.aqi_hour', {}),
  data: _.get(state, 'stationStore.dataAqi', []),
  aqiLast: _.last(_.get(state, 'stationStore.dataAqi', [])),
  indicators: _.get(state, 'stationStore.indicators', []),
}))
class AqiContainer extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = { data: props.data || [] }
  }

  getAqiFrom(val) {
    if (val === null) return { value: '-', status: '' }
    val = _.round(val)
    const level = _.find(aqiLevels, ({ to, from }) => _.inRange(val, from, to))
    return { ...level, value: val }
  }

  async componentDidMount() {
    // const { data } = await api.get_data_of_day('28860836867263985683146268943')
    //this.setState({ data: data })
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.data, nextProps.data)) this.setState({ data: nextProps.data })
  }

  getTitle() {
    let time = moment()
    if (this.props.station.qi_time) time = moment(this.props.station.qi_time).subtract(7, 'hours')
    return `AQI: ${_.padStart(time.hours() - 1, 2, '0')} - ${_.padStart(time.hours(), 2, '0')}h`
  }

  render() {
    return (
        <View className='de-container'>
          <StationSelected />
          <Recommandation {...this.getAqiFrom(_.get(this.props, ['station', 'qi'], null))} />
          <Body>
            <Left>
              <InfoView TextFirst {...this.getAqiFrom(_.get(this.props, ['station', 'qi'], null))} title={this.getTitle()} />
              <InfoView TextFirst {...this.getAqiFrom(_.get(this.props, ['aqi', 'aqi'], null))} title='AQI Ngày' />
            </Left>
            <Content>
              <ContentContainer indicators={this.props.indicators} data_min={this.props.data_min} data={this.state.data} station={this.props.station} />
            </Content>
            <Right>
              <InfoView TextFirst {...this.getAqiFrom(_.get(this.props, ['aqi_hour', 'PM-2-5'], null))} status='PM 2.5' title={this.getTitle()} />
              <InfoView TextFirst {...this.getAqiFrom(_.get(this.props, ['aqi', 'PM-2-5'], null))} status='PM 2.5' title='AQI Ngày' />
            </Right>
          </Body>
          <LevelContainer />
        </View>
    )
  }
}

export default AqiContainer 