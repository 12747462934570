import React from 'react'
import './index.css'
import './led-multiple.css'
import LevelContainer from "./level-wqi";
import * as _ from 'lodash'

import aqiLevels from '../../../constants/aqi'
import TableHeader from './wqiMB-table-header'
import TableRow from './wqi-table-row'


function getAqiFrom(val) {
  if (val === null) return { value: '-', status: '' }
  val = _.round(val)
  const level = _.find(aqiLevels, ({ to, from }) => _.inRange(val, from, to))
  return { ...level, value: val }
}

const WqiMBView = props => {
  return (
    <div className='slide-content'>
      <TableHeader />
      <div className='led-multiple-content-table'>
        {
          props.wqiStationsList && props.wqiStationsList.map(item => {
            const aqi_data = getAqiFrom(item.value)
            const aqi_day_data = getAqiFrom(item.value)
            return (
              <TableRow aqi_data={aqi_data} aqi_day_data={aqi_day_data} item={item} />
            )
          })
        }
      </div>
      <div className='led-footer led-border-top-big'>
        <LevelContainer />
      </div>
    </div>
  )
}
export default WqiMBView