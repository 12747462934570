import React, { Component } from 'react'
import './index.css'
import './led-multiple.css'
import styled from 'styled-components'
import api from "../../api";
import * as _ from 'lodash'
import Clock from './clock'
import Timer from './timer'
import AqiView from './aqi-view'
import WqiView from './wqi-view'
import { Carousel, Table } from 'antd';
import { getStationListWQI } from '../../api/api-qtdk'

import AqiMBView from './component/aqiMB-view';
import WqiMBView from './component/wqiMB-view';
import TableMB from './component/tableMB';
import TableMBHeader from './component/aqiMB-table-header'
import './mobile.css'

const ImageLogo = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 12px
`

const station_type = 4

export default class LedAQI extends Component {

    constructor(props) {
        super(props)
        this.state = {
            width: window.innerWidth,
            aqiStationsList: [],
            wqiStationsList: []
        }
    }

    fetchAqi = async () => {
        const { success, data } = await api.get_stations(station_type)
        if (success) {
            if (!_.isEqual(data, this.state.stationsList)) {
                this.setState({ aqiStationsList: _.chunk(data, 5) })
            }
        }
    }
    fetchWqi = async () => {
        const { success, data } = await getStationListWQI()
        if (success) {
            if (!_.isEqual(data, this.state.wqiStationsList)) {
                this.setState({ wqiStationsList: _.chunk(data, 5) })
            }
        }
    }


    fetchData = () => {
        this.fetchAqi()
        this.fetchWqi()
    }
      /* Chia độ phân giải màn hình*/

      componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
      }
      
      // make sure to remove the listener
      // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
      }
      
      handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
      };

    /* End Chia độ phân giải màn hình*/

    render() {
        const { width } = this.state;
        const isMobile = width <= 700;
        if(isMobile){
            return<div className='ledMB-container'>
                <div className='ledMB-header'>
                    <div>
                        <ImageLogo style={{width: "50px",height: "50px"}} src='./logo.png' />
                        <h3 className='headerMB-text'>SỞ TÀI NGUYÊN VÀ MÔI TRƯỜNG TỈNH GIA LAI {this.state.number}</h3>
                    </div>
                    
                </div>
                <div className='clock-mb'>
                        <Clock />
                </div>
                <Timer fetchData={this.fetchData} />
                <div className='page-name'>
                    <p>Chỉ số chất lượng không khí VN_AQI</p>
                </div>
                <TableMB aqiStationList={this.state.aqiStationsList}
                        wqiStationsList={this.state.wqiStationsList}
                />
               
               
                
            </div>
        }
        else
        return (
            <div className='led-container'>
                <div className='led-header'>
                    <div>
                        <ImageLogo src='./logo.png' />
                        <h3 className='text-blue'>SỞ TÀI NGUYÊN VÀ MÔI TRƯỜNG TỈNH GIA LAI {this.state.number}</h3>
                    </div>
                    <div>
                        <Clock />
                    </div>
                </div>
                <div className='slide-multiple'>
                    <Carousel autoplay autoplaySpeed={20000} className='carousel' dots={false}>
                        {this.state.aqiStationsList.map(item => (
                            <AqiView stationsList={item} />
                        ))}

                        {this.state.wqiStationsList.map(item => (
                            <WqiView wqiStationsList={item} />
                        ))}
                    </Carousel>
                </div>
                <Timer fetchData={this.fetchData} />
            </div >
        )
    }
}
