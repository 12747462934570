import React from 'react'
import styled from 'styled-components'
import aqiLevels from '../../../constants/aqi'
import { Tooltip } from 'antd'
const View = styled.div`
  display: flex;
  flex-direction: row;
`
const Text = styled.div`font-size: 14px; font-weight: 700;`
const Note = styled.div`font-size: 12px; font-weight: 500; font-style: italic;`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: ${props => props.bgColor || '#00e400'}
  color: ${props => props.tColor || '#000'}
`

const RowLev = styled(Tooltip)` 
  flex: 1; 
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  background-color: ${props => props.bgColor || '#00e400'}

`
const ViewEl = ({ status,name,color,note }) => (
  <RowLev title={note} bgColor={color}>
    {/* <Stick bgColor={color}/> */}
    <Col bgColor='transparent'>
      <Text>{name}</Text>
      <Note>{status}</Note>
    </Col>
  </RowLev>
)

export default class LevelContainer extends React.PureComponent {
  render() {
    return (
      <View style={{
        // borderTopColor: '#ddd', borderTopWidth: 0.5, borderTopStyle: 'solid'
      }}>
        {
          aqiLevels.map(it => <ViewEl key={it.name} {...it} />)
        }
      </View>
    )
  }
}