import React from 'react'
import './index.css'
import './led-multiple.css'
import styled from 'styled-components'
import wqiLevels from '../../constants/wqi'
import moment from 'moment'


const AQITitleText = styled.div`font-size: 18px; font-weight: 500; color: ${props => props.color || '#3333FF'}; text-align: justify`
const AQINoteText = styled.div`font-size: 16px; font-weight: 300; color: ${props => props.color || '#fff'}; text-align: center`
const AQIValueText = styled.div`font-size: 32px; font-weight: 900; color: ${props => props.color || '#3333FF'};text-align: center`
const AQIStatusText = styled.div`font-size: 16px; font-weight: 300; color: ${props => props.color || '#3333FF'};text-align: center`

const TableRow = props => {
  console.log('-............?', props)
  return (
    <div className='led-multiple-content led-border-top-big'>
      <div className='led-multiple-content-child led-border-right-big'>
        <AQITitleText color={wqiLevels[props.item.status]['color']}>
          {props.item.station.name}
        </AQITitleText>
      </div>
      <div className='led-multiple-content-wqi-child led-border-right-big'>
        <AQINoteText>
          Từ: {moment(props.item.analysisAt.from).subtract({ hours: 7 }).format('DD/MM/YYYY')}
        </AQINoteText>
        <AQINoteText>
          Đến: {moment(props.item.analysisAt.to).subtract({ hours: 7 }).format('DD/MM/YYYY')}
        </AQINoteText>
      </div>

      <div className='led-multiple-content-wqi-child led-border-right-big'>
        <AQIValueText color={wqiLevels[props.item.status]['color']}>
          {props.aqi_day_data.value}
        </AQIValueText>
        <AQIStatusText color={wqiLevels[props.item.status]['color']}>
          {wqiLevels[props.item.status]['title']}
        </AQIStatusText>
      </div>
      <div className='led-multiple-content-last-child'>
        <AQITitleText color={wqiLevels[props.item.status]['color']}>
          {wqiLevels[props.item.status]['content']}
        </AQITitleText>
      </div>
    </div>
  )
}
export default TableRow

