export const MEASURINGS = ['SO2','CO','NO2','O3','PM-10','PM-2-5']
export default [
  {
      
      "color": "#00e400",
      "to": 51,
      "from": 0,
      "name": "0 - 50",
      "status": "Tốt",
      textColor: '#fff',
      note: 'Chất lượng không khí tốt, không ảnh hưởng tới sức khỏe'
  },
  {
      "color": "#ffff00",
      "to": 101,
      "from": 51,
      "name": `51 - 100`,
      "status": "Trung bình",
      textColor: '#000',
      note: 'Chất lượng không khí ở mức chấp nhận được. Tuy nhiên, đối với những người nhạy cảm (Người già, trẻ em, người mắc các bện hô hấp tim mạnh...) có thể chụi những tác động nhất định tới sức khỏe.'
  },
  {
      "color": "#ff7e00",
      "to": 151,
      "from": 101,
      "name": "101 - 150",
      "status": "Kém",
      textColor: '#fff',
      note: 'Những người nhạy cảm gặp phải các vấn đề về sức khỏe, những người bình thường ít ảnh hưởng.'
  },
  {
      "color": "#ff0000",
      "to": 201,
      "from": 151,
      "name": "151 - 200",
      "status": "Xấu",
      textColor: '#fff',
      note: 'Những người bình thường bắt đầu có các ảnh hưởng tới sức khỏe, nhóm người nhạy cảm có thể gặp những vấn đề về sức khỏe nghiêm trọng hơn.'
  },
  {
      "color": "#8f3f97",
      "to": 301,
      "from": 201,
      "name": "201 - 300",
      "status": "Rất xấu",
      textColor: '#fff',
      note: 'Cảnh báo hưởng tới sức khỏe: mọi người bị ảnh hưởng tới sức khỏe nghiêm trọng hơn.'
  },
  {
      "color": "#7e0019",
      "to": 500.0001,
      "from": 301,
      "name": "301-500",
      "status": "Nguy hại",
      textColor: '#fff',
      note: 'Cảnh báo khẩn cấp về sức khỏe: Toàn bộ dân số bị ảnh hưởng tới sức khỏe nghiêm trọng.'
  }
]