import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import { Alert } from 'antd'

const RECOMMENDATION_CONTENT = {
    "0 - 50": ["Tự do thực hiện các hoạt động ngoài trời.", "Tự do thực hiện các hoạt động ngoài trời."],
    "51 - 100": ["Tự do thực hiện các hoạt động ngoài trời.", "Nên theo dõi các triệu chứng như ho hoặc khó thở, nhưng vẫn có thể hoạt động bên ngoài."],
    "101 - 150": ["Những người thấy có triệu chứng đau mắt, ho hoặc đau họng... nên cân nhắc giảm các hoạt động ngoài trời. Đối với học sinh, có thể hoạt động bên ngoài, nhưng nên giảm bớt việc tập thể dục kéo dài.", "Nên giảm các hoạt động mạnh và giảm thời gian hoạt động ngoài trời. Những người mắc bệnh hen suyễn có thể cần sử dụng thuốc thường xuyên hơn."],
    "151 - 200": ["Mọi người nên giảm các hoạt động mạnh khi ở ngoài trời, tránh tập thể dục kéo dài và nghỉ ngơi nhiều hơn trong nhà.", "Nên ở trong nhà và giảm hoạt động mạnh. Nếu cần thiết phải ra ngoài, hãy đeo khẩu trang đạt tiêu chuẩn."],
    "201 - 300": ["Mọi người hạn chế tối đa các hoạt động ngoài trời và chuyển tất cả các hoạt động vào trong nhà. Nếu cần thiết phải ra ngoài, hãy đeo khẩu trang đạt tiêu chuẩn.", "Nên ở trong nhà và giảm hoạt động mạnh."],
    "301-500": ["Mọi người nên ở trong nhà, đóng cửa ra vào và cửa sổ. Nếu cần thiết phải ra ngoài, hãy đeo khẩu trang đạt tiêu chuẩn.", "Mọi người nên ở trong nhà, đóng cửa ra vào và cửa sổ. Nếu cần thiết phải ra ngoài, hãy đeo khẩu trang đạt tiêu chuẩn."]
}


const Recommendation = (props) => {
    const [name, setName] = useState(_.get(props, 'name', null))
    useEffect(() => {
        setName(props.name)
    }, [props.name]);
    if (name)
        return (
            <div style={{ margin: 6 }}>
                <Alert
                    message={
                        <div>
                            <div><b>Khuyến nghị về sức khỏe</b></div>
                            <div>{`Đối với người bình thường: ${RECOMMENDATION_CONTENT[name][0]}`}</div>
                            <div>{`Đối với người nhạy cảm: ${RECOMMENDATION_CONTENT[name][1]}`}</div>
                        </div>}
                    type="info"
                    showIcon
                />
            </div>
        )
    return (<div></div>)
}

export default Recommendation

