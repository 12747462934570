import React, { useState, useEffect } from 'react'
import { STATUS_WQI } from './box-wqi'
import * as _ from 'lodash'
import { Alert } from 'antd'

const COLOR_ALERT = ['HEAVY_POLLUTION', 'VERRY_HEAVY_POLLUTION']

const Recommendation = (props) => {
    const [stationSelect, setStationSelect] = useState(_.get(props, 'stationSelect', null))
    useEffect(() => {
        setStationSelect(props.stationSelect)
    }, [props.stationSelect]);
    if (stationSelect)
        return (
            <div style={{ margin: 6 }}>
                <Alert
                    message={
                        <div>
                            <div><b>Khuyến cáo mục đích sử dụng</b></div>
                            <div>{STATUS_WQI[stationSelect['status']]['content']}</div>
                        </div>}
                    type={_.includes(COLOR_ALERT, stationSelect['status']) ? "warning" : "info"}
                    showIcon
                />
            </div>
        )
    return (<div></div>)
}

export default Recommendation

