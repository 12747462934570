import React from 'react'
import styled from 'styled-components';
import StationCom from '../../components/station-selected';
import ChartStation from './chart-data';

const WrapperView = styled.div`
  min-height: 254px;
`

class StationComponent extends React.Component {
  render() {
    return (
      <WrapperView>
        <StationCom />
        <ChartStation />
      </WrapperView>
    )
  }
}

export default StationComponent