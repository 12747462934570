import React from 'react'
import * as _ from 'lodash'
import styled from 'styled-components'
import { MEASURINGS } from '../../constants/aqi'

const Wrapper = styled.div`
flex-direction: row;
display: flex; width: 100%; height: 24px;
justify-content: start;
align-items: center;
align-content: center;
background-color: #fafafa;
`

const El = styled.div`
font-size: 8px; padding: 0px 4px; cursor: pointer; font-weight: bold;
color: ${props => props.color || 'rgba(0, 0, 0, 0.65)'};
`

const Span = styled.span`margin-right: 2px; color: ${props => props.color || 'rgba(0, 0, 0, 0.65)'};`

class Element extends React.PureComponent {
  state = { selected: true }
  handleChange = e => {
    e.preventDefault()
    this.setState({ selected: !this.state.selected }, () => {
      if (this.props.onLegendChange)
        this.props.onLegendChange(this.state.selected, this.props.val)
    })
  }
  render() {
    return (
      <El {...this.props} onClick={this.handleChange} color={this.state.selected ? this.props.color : '#00000066'}>
        <Span color={this.props.color}>●</Span> {this.props.val}
      </El>
    )
  }
}

export default class Com extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        {_.map(MEASURINGS, (it, inx) => <Element onLegendChange={this.props.onLegendChange} color={this.props.colors[inx]} key={it} val={it} />)}
      </Wrapper>
    )
  }
}
