import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import './index.css'
import styled from 'styled-components'

const View = styled.div`
    display: flex; 
    align-items: center;
    justify-content: center;
`

const TITLE_BOX = 'VN-WQI'
export const STATUS_WQI = {
    VERRY_GOOD: {
        title: 'Rất tốt',
        content: 'Sử dụng tốt cho mục đích cấp nước sinh hoạt',
        color: '#3333FF',
        text_color: '#FFFFFF'
    },
    GOOD: {
        title: 'Tốt',
        content: 'Sử dụng cho mục đích cấp nước sinh hoạt nhưng cần các biện pháp xử lý phù hợp',
        color: '#00FF00',
        text_color: '#FFFFFF'
    },
    NORMAL: {
        title: 'Trung bình',
        content: 'Sử dụng cho mục đích tưới tiêu và các mục đích tương đương khác',
        color: '#FFFF00',
        text_color: '#3F3A3A'
    },
    POOR: {
        title: 'Kém',
        content: 'Sử dụng cho giao thông thủy và các mục đích tương đương khác',
        color: '#FF7E00',
        text_color: '#FFFFFF'
    },
    HEAVY_POLLUTION: {
        title: 'Ô nhiễm nặng',
        content: 'Nước ô nhiễm nặng, cần các biện pháp xử lý trong tương lai',
        color: '#FF0000',
        text_color: '#FFFFFF'
    },
    VERRY_HEAVY_POLLUTION: {
        title: 'Ô nhiễm rất nặng',
        content: 'Nước nhiễm độc, cần có biện pháp khắc phục, xử lý',
        color: '#7E0023',
        text_color: '#FFFFFF'
    }
}

export default function BoxWQI(props) {
    const [stationSelect, setStationSelect] = useState(null)

    useEffect(() => {
        setStationSelect(props.stationSelect)
    }, [props.stationSelect])

    return (
        <div className='box-wqi' style={{ display: 'flex', flexDirection: 'column', width: 280, height: 240, backgroundColor: stationSelect && STATUS_WQI[stationSelect.status]['color'], color: stationSelect && STATUS_WQI[stationSelect.status]['text_color'] }}>
            <View style={{ flexGrow: 1, fontSize: 24 }}>
                <b>{TITLE_BOX}</b>
            </View>
            <View style={{ flexGrow: 4 }}>
                <b style={{ fontSize: 56 }}>{_.get(stationSelect, 'value', null)}</b>
            </View>
            <View style={{ flexGrow: 1, fontSize: 24 }}>
                <b>{stationSelect && STATUS_WQI[stationSelect.status]['title']}</b>
            </View>
        </div>
    )
}
