import { postFetch } from '../utils/fetch'
import { toUrl } from '../config-app'

export default {
  get_stations: station_type => {
    return postFetch(toUrl('api/stations'), { station_type })
  },
  get_data: ({ station_id, indicators }) => {
    return postFetch(toUrl('api/data'), { station_id, indicators })
  },
  get_api_day: station_id => {
    return postFetch(toUrl('api/aqi-day'), { station_id })
  },
  get_data_of_day: params => {
    return postFetch(toUrl('api/v2-data-hour-of-day'), params)
  }
}