import React from 'react'
import styled from 'styled-components'
import connectAutoDispatch from '../../@redux/connect'
import * as _ from 'lodash'
import Highcharts from 'react-highcharts'
// import moment from 'moment'
// import ChartLegend from './chart-legend'
// import { MEASURINGS } from '../../constants/aqi'

const colors = Highcharts.Highcharts.getOptions().colors

const Wrapper = styled.div`
flex-direction: column;
display: flex; width: 100%; height: 210px;
margin: 6px;
padding-right: 6px;
`
// @connectAutoDispatch((state,ownProps) => {
//   const indicator = _.get(ownProps,'indicator',{})
//   let data = _.get(state,'stationStore.data',[])
//   let max = 0
//   data = _.map(data,item => {
//     const val = _.toNumber(_.get(item,[indicator.name],0))
//     if (val > max) max = val
//     return [item.time,val]
//   })
//   return {
//     max,
//     indicator,
//     data,
//     station: _.get(state,'stationStore.station',{}),
//     aqiDay: _.get(state,'stationStore.aqiDay',{}),
//   }
// })

function toIndicatorName(name, qcvnMin, qcvnMax,) {
  if (qcvnMin && qcvnMax) {
    return `${name} (${qcvnMin} - ${qcvnMax})`
  }
  if (qcvnMin) {
    return `${name} (>= ${qcvnMin})`
  }
  if (qcvnMax) {
    return `${name} (<= ${qcvnMax})`
  }
  return name
}

@connectAutoDispatch(state => ({
  indicators: _.get(state, 'stationStore.indicators', []),
  data: _.get(state, 'stationStore.data', []),
}))
class ChartView extends React.Component {

  // shouldComponentUpdate(nextProps) {
  //   return !_.isEqual(nextProps.data,this.props.data)// || !_.isEqual(nextProps.indicator,this.props.indicator);
  // }

  render() {
    const indicators = _.keyBy(this.props.indicators, 'key')
    const yAxis = {
      lineWidth: 1,
      title: {
        text: ''
      },
      min: 0,
      type: 'linear'
    }
    if (_.get(this.props, 'indicator.qcvn_detail_max_value')) {
      const val = _.toNumber(_.get(this.props, 'indicator.qcvn_detail_max_value'))
      if (val > yAxis.max) yAxis.max = val + 10
      yAxis.plotLines = [
        {
          value: val,
          color: 'red',
          dashStyle: 'shortdash',
          width: 2,
          label: {
            text: `Max ${val}`
          }
        }
      ]
    }


    const rs = {}
    let d = this.props.data

    _.forEach(d, item => {
      _.forEach(this.props.indicators, ind => {
        const m = ind.key
        if (!rs[m]) rs[m] = []
        rs[m].push([item.time, _.toNumber(_.get(item, [m], 0))])
      })
    })
    const series = []
    let inx = 0
    _.forIn(rs, (values, key) => {
      series.push({
        marker: { enabled: false },
        name: toIndicatorName(indicators[key].name, indicators[key].qcvn_detail_min_value, indicators[key].qcvn_detail_max_value),
        data: values,
        lineWidth: 1.5,
        color: colors[inx]//Highcharts.getOptions().colors[i]
      })
      inx = inx + 1
    })



    return (
      <Wrapper>
        <Highcharts config={
          {
            chart: {
              type: 'spline',
              zoomType: 'x',
              // width: 462,
              height: 210,//118,
              backgroundColor: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                  [0, '#cce1fc'], // start
                  [1, '#9fc9fc'] // end
                ]
              },
              spacing: [6, 6, 12, 6],
              style: {
                fontFamily: 'Times New Roman'
              },
            },
            credits: {
              enabled: false
            },
            title: {
              text: '',
            },
            subtitle: {
              text: ''
            },
            tooltip: {
              xDateFormat: '<b>Lúc</b>: <i>%H:%M %d-%m-%Y</i>',
              valueDecimals: 2,
              pointFormat: "<span style='color:{point.color}'>●</span> {series.name}: <b>{point.y}</b><br/>"
            },
            xAxis: {
              // categories,
              type: 'datetime',
              dateTimeLabelFormats: {
                millisecond: '%H:%M',
                second: '%H:%M:%S',
                minute: '%H:%M',
                hour: '%H:%M',
                day: '%e. %b',
                week: '%e. %b',
                month: '%b \'%y',
                year: '%Y'
              }
            },
            yAxis,
            legend: {
              symbolPadding: 2, symbolWidth: 8, showCheckbox: true, enabled: true,
              itemDistance: 5, padding: 2, lineHeight: 10, margin: 4,
              itemStyle: {
                // color: 'white',
                fontWeight: 'bold',
                fontSize: '8px'
              },
            },
            series
          }
        } />
        {/* <ChartLegend colors={colors} /> */}
      </Wrapper>
    )
  }
}
export default ChartView