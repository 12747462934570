import React from 'react'
import styled from 'styled-components'
import * as _ from 'lodash'
import './data-list.css'
import moment from 'moment'
import { MEASURINGS } from '../../constants/aqi'
const Table = styled.table``
const TBody = styled.tbody``
const TR = styled.tr``
const TD = styled.td`
text-align: center; 
padding: 4px 4px;
line-height: 1.4;
vertical-align: middle;
border-top: 1px solid #ddd;
`
const THead = styled.thead``
const TH = styled.th` line-height: 1.4; padding: 4px; font-size: 8px;`

// const MEASURINGS = ['SO2','CO','NO2','O3','PM-10','PM-2-5', 'Temp', 'RH']


export default class DataListContainer extends React.PureComponent {

  constructor(props) {
    super(props)

    this.state = this.preExecData(props.data || [])
  }

  preExecData(ls) {
    let data = []
    let inx = 0;
    _.forEachRight(ls, (d) => {
      if (inx < 3) {
        inx = inx + 1;
        data.push(d)
      }
    })
    //data = _.slice(data, 7, 10)

    const rs = { data }
    rs.columns = [
      {
        title: 'Thời gian',
        dataIndex: 'time',
        key: 'thoigian',
      }
    ]//_.map(MEASURINGS,m => )
    rs.columns.push(..._.map(data, m => {
      const t = m.thoigian.format('HH') + 'h'
      return {
        title: t,
        align: 'center',
        dataIndex: t,
        key: t,
      }
    }))

    return rs
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.data, nextProps.data)) this.setState(this.preExecData(nextProps.data || []))
  }

  toVal(key, item) {
    if (key === 'get_time')
      return moment(item[key]).subtract(7, 'hours').format('HH') + 'h'
    const val = _.get(item, ['data', key], null)
    if (val === null) return '-'
    return _.round(val, 2)
  }

  render() {
    return (
      <Table className='d-table d-table-bordered'>
        <THead>
          <TR>
            {
              this.state.columns.map((it, i) => <TH style={{ textAlign: i === 0 ? 'left' : 'center', fontSize: 13 }} key={it.key}>{it.title}</TH>)
            }
          </TR>
        </THead>
        <TBody>
          {_.map(MEASURINGS, (m, i) => (
            <TR key={m}>
              <TD style={{ textAlign: 'left', fontSize: 13 }} key={`${i}`}>{m}</TD>
              {
                this.state.data.map((it, i) =>
                  <TD style={{ fontSize: 13 }} key={`${i}`}>{this.toVal(m, it)}</TD>
                )}
            </TR>
          ))}

        </TBody>
      </Table>
    )
  }
}