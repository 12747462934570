import React, { useState } from 'react'
import SelectStation from './select-station'
import BoxWQI from './box-wqi'
import BoxRecommandation from './box-recommendation'
import BoxChart from './box-chart'
import styled from 'styled-components'
import LevelContainer from './level'
import './index.css'

const View = styled.div`
    padding-top: 10px;
   
`
const WQIBox = () => {
    const [stationSelect, setStationSelect] = useState(null)
    const getValueStation = (value) => {
        setStationSelect(value)
    }

    return (
        <div className='de-container tab-content' >
            <div className="wd-panel-h hidden-xs">
                <b className="wd-panel-h-title">
                    <span>Dữ liệu quan trắc định kỳ</span>
                </b>
            </div>
            <div>
                <View>
                    <SelectStation getValueStation={getValueStation} />
                </View>
                <View id='box-recommandation'>
                    <BoxRecommandation stationSelect={stationSelect} />
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <BoxWQI stationSelect={stationSelect} />
                    <BoxChart stationSelect={stationSelect} />
                </View>
            </div>
            <LevelContainer />
        </div >
    )
}

export default WQIBox
