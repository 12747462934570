import React from 'react'
import {Route,Switch } from 'react-router-dom'
import slug from '../constants/slug'
import HomeContainer from '../containers/app'
import WQIQTDKContainer from '../containers/wqi-qtdk'
import LedAQIContainer from '../containers/led-aqi'
import Led from '../containers/led-multiple';

export default props => (
  <Switch>
    <Route exact path={slug.led} component={Led} {...props}/>
    <Route path={slug.home} component={HomeContainer} {...props}/>
    <Route path={slug.wqi_qtdk} component={WQIQTDKContainer} {...props}/>
    <Route path={slug.led_aqi} component={LedAQIContainer} {...props}/>
  </Switch>
)
