import { connect as reduxConnect } from 'react-redux'
import { bindActionCreators } from 'redux'

export default function connectAutoDispatch(
  mapStateToProps = () => ({}),
  actions = {},
  ...args
) {
  return reduxConnect(
    mapStateToProps,
    dispatch => bindActionCreators(actions, dispatch),
    ...args
  )
}