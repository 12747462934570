import React from 'react'
import styled from 'styled-components'
import { Tabs } from 'antd'
import AqiView from '../aqi'
import './styles.css'
import connect from '../../@redux/connect'
import { fetchStations } from '../../@redux/actions/station'
import * as _ from 'lodash';
import StationComponent from '../station';

const { TabPane } = Tabs
const View = styled.div``
const INTERVAL = 600000
@connect(state => ({ station_type: _.get(state, 'stationStore.station.station_type', '4') }), { fetchStations })
class AppContainer extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      key: props.station_type
    }
  }

  handleTabChange = key => {
    this.setState({ key }, () => {
      this.startTimer(key)
    })
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.station_type, this.props.station_type)) this.setState({ key: nextProps.station_type })
  }

  componentDidMount() {
    this.startTimer(this.props.station_type)
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  startTimer(type) {
    let params = new URLSearchParams(this.props.location.search)
    const ignore = params.has('ignore') ? params.get('ignore').split(',') : null
    this.stopTimer()
    this.props.fetchStations(type, null, ignore)
    this.timer = setInterval(() => {
      this.props.fetchStations(type, null, ignore)
    }, INTERVAL);
  }

  render() {
    return (

      <View className='de-container tab-content'>
        <div className="wd-panel-h hidden-xs">
          <b className="wd-panel-h-title">
            <span>Dữ liệu quan trắc tự động</span>
          </b>
        </div>
        <Tabs activeKey={`${this.state.key}`} onChange={this.handleTabChange}>
          <TabPane tab='Không Khí' key='4'>
            <AqiView />
          </TabPane>
          <TabPane tab='Nước Mặt' key='1'>
            <StationComponent />
          </TabPane>
        </Tabs>
      </View >

    )
  }
}

export default AppContainer