if (process.env.NODE_ENV !== 'production') {
  require('dotenv').config()
}

export const API = process.env.WEB_API || 'https://led.tnmtgialai.gov.vn'
export const API_QTDK = process.env.API_QTDK || 'https://api.qtdk.tnmtgialai.gov.vn'
export const AT = process.env.AT || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NDg3ODc3NDYsImV4cCI6MTY4MDMyMzc0Niwic3ViIjoiNjFjYmJlNmQwZTIyNGQ3NDE3ODQ5Y2Q4IiwicmV2b2tlZCI6IlIyMzc5In0.6yyD9BG_OMQqGTbZ8j05J_2ql-alaHYX2ZEDURcfRiY'
export const PORT = process.env.PORT || 5055

export function toAuthUrl(prefix = '') {
  if (prefix)
    return `${API}/${prefix}`
  return API
}

export function toUrl(prefix = '') {
  if (prefix)
    return `${API}/${prefix}`
  return API
}

export function toUrlQTDK(prefix = '') {
  if (prefix)
    return `${API_QTDK}/${prefix}`
  return API_QTDK
}
