import * as _ from 'lodash'
import BigNumber from 'bignumber.js'
import aqiLevel from '../constants/aqi'
import moment from 'moment';

export function remove_unicode(str) {
  str = str.toLowerCase();
  let from = "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñç",
    to = "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouunc";
  for (var i = 0,l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i],"gi"),to[i]);
  }
  return str;
}

export function long2hex(val) {
  if (val)
    return BigNumber(val).toString(16)
  return null
}

export function hex2long(val) {
  const tmp = BigNumber(val,16)
  return _.join(tmp.c,'')
}

export const AqiEx = {
  get: (val,key, df = '-') => {
    const level = getAqiLevel(val)
    if (level) return level[key]
    return df
  },
  level: (val) => {
    const level = getAqiLevel(val)
    if (level) return level.status
    return '-'
  },
  value: (val) => {
    if (val === null) return '-'
    return _.floor(val)
  },
  toTime: (val) => {
    if (!val) return '-'
    const time = moment(val).subtract(7,'hour')
    return `${time.hour() - 1}-${time.hour()}h`
  }
}

export function getAqiLevel(val) {
  if (val === null) return null
  const level = _.find(aqiLevel,({ to,from }) => _.inRange(val,from,to))
  return level
}
