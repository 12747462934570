export default {
  VERRY_GOOD: {
    title: 'Rất tốt',
    name: '91 - 100',
    content: 'Sử dụng tốt cho mục đích cấp nước sinh hoạt',
    color: '#3333FF',
    text_color: '#FFFFFF'
  },
  GOOD: {
    title: 'Tốt',
    name: '76 - 90',
    content: 'Sử dụng cho mục đích cấp nước sinh hoạt nhưng cần các biện pháp xử lý phù hợp',
    color: '#00FF00',
    text_color: '#FFFFFF'
  },
  NORMAL: {
    title: 'Trung bình',
    name: '51 - 75',
    content: 'Sử dụng cho mục đích tưới tiêu và các mục đích tương đương khác',
    color: '#FFFF00',
    text_color: '#3F3A3A'
  },
  POOR: {
    title: 'Kém',
    name: '26 - 50',
    content: 'Sử dụng cho giao thông thủy và các mục đích tương đương khác',
    color: '#FF7E00',
    text_color: '#FFFFFF'
  },
  HEAVY_POLLUTION: {
    title: 'Ô nhiễm nặng',
    name: '10 - 25',
    content: 'Nước ô nhiễm nặng, cần các biện pháp xử lý trong tương lai',
    color: '#FF0000',
    text_color: '#FFFFFF'
  },
  VERRY_HEAVY_POLLUTION: {
    title: 'Ô nhiễm rất nặng',
    name: '< 10',
    content: 'Nước nhiễm độc, cần có biện pháp khắc phục, xử lý',
    color: '#7E0023',
    text_color: '#FFFFFF'
  }
}
